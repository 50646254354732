import React from 'react';
import { graphql } from 'gatsby';

import Layout from "../components/layout";
import Head from '../components/head';

export const query = graphql`
    query($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            frontmatter {
                title
                date
            }
            html
        }
    }
`;

const Blog = ({ data }) => {
    return (
        <Layout>
            <Head title={data.markdownRemark.frontmatter.title} />
            <div className='blog-page'>
                <div className='blog-page__info'>
                    <h1>{data.markdownRemark.frontmatter.title}</h1>
                    <p>{data.markdownRemark.frontmatter.date}</p>
                </div>
                <div className='blog-page__content' dangerouslySetInnerHTML={{ __html: data.markdownRemark.html}}></div>
            </div>
        </Layout>
    );
};

export default Blog;